/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Header from "../layout/header"
// import { PortableText } from "@portabletext/react"
import { GatsbyImage } from "gatsby-plugin-image"
import Container from "../components/container"
import BlockContent from "@sanity/block-content-to-react"
import Serializers from "../components/serializers/serializers"
import { getGatsbyImageData } from "gatsby-source-sanity"

const sanityConfig = {
  projectId: "mo0idlaa",
  dataset: "production",
}
const Hero = ({ node }) => {
  const entity = node._rawHero[0]
  if (entity.backgroundImage) {
    var backgroundImage = getGatsbyImageData(
      entity.backgroundImage.asset,
      { maxWidth: 3000 },
      sanityConfig
    )
  }
  return (
    <div
      className="hero-outer"
      sx={{
        width: "100%",
        position: "relative",
        //height: ["262px", "613px"],
        height: ["613px"],
      }}
    >
      {backgroundImage && (
        <GatsbyImage
          layout="constrained"
          image={backgroundImage}
          alt="Hero Image"
          title="Hero Image"
          sx={{ width: "100%", height: "100%" }}
        />
      )}

      <div
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
        }}
      >
        <Header />

        <div
          className="hero-copy-container"
          sx={{
            padding: "0px 5%",
            margin: "0 auto",
            position: "absolute",
            bottom: "90px",
          }}
        >
          <div className="contact-hero">
            <BlockContent blocks={entity.body} serializers={Serializers} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
