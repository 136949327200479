/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Burger from "../components/burger"
import Menu from "../components/menu"
import Container from "../components/container"
import Logo from "../components/Logo"
import SocialLinks from "../components/atoms/SocialLinks"
import Phone from "../images/PhoneSVG.svg"

class Header extends React.Component {
  constructor(props) {
    super(props)
    // this.state = { value: '' };
    this.state = {
      menuOpen: false,
      updateOpen: null,
    }
    this.toggleMenu = this.toggleMenu.bind(this)
  }
  toggleMenu() {
    this.setState(prevState => ({
      menuOpen: !prevState.menuOpen,
    }))
  }
  componentWillMount() {}
  render() {
    return (
      <StaticQuery
        query={graphql`
          query HeadingQuery {
            digettlogo: file(name: { eq: "digettlogo" }) {
              name
              publicURL
            }
          }
        `}
        render={data => (
          <header
            sx={{
              zIndex: "11",
              width: "100%",
              position: "relative",
              backgroundImage:
                "linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0.6))",
              paddingBottom: this.props.header !== false ? "40px" : "0",
            }}
          >
            <Container fullWidth noMobilePadding={true}>
              <div>
                <div
                  sx={{
                    backgroundColor: [
                      "rgba(249, 248, 246, 0.88);",
                      "rgba(249, 248, 246, 0.88)",
                    ],
                    padding: "14px 0px 10px",
                  }}
                >
                  <Container>
                    <div
                      sx={{
                        display: "flex",
                        justifyContent: ["flex-start", "flex-end"],
                        alignItems: "center",
                        color: "#575F6B",
                      }}
                    >
                      <div
                        sx={{
                          marginRight: "40px",
                        }}
                      >
                        Anthony Simpson, Broker
                      </div>
                      <a href="tel:830-955-1725">
                        <img
                          sx={{
                            position: "absolute",
                            right: "55px",
                            top: "7px",
                            display: ["block", "none", "none"],
                          }}
                          src={Phone}
                          alt="phone"
                        />
                        <div
                          sx={{
                            marginRight: ["0rem", "-2rem"],
                            display: ["none", "block", "block"],
                          }}
                        >
                          830-955-1725
                        </div>
                      </a>
                      <div
                        sx={{
                          marginLeft: "2rem",
                        }}
                      >
                        <Burger
                          clickMe={this.toggleMenu}
                          open={this.state.menuOpen}
                        />
                      </div>
                    </div>
                  </Container>
                </div>
                {this.props.header !== false && (
                  <Container>
                    <div
                      sx={{
                        marginRight: ["0px", "0px", "0px"],
                        position: "relative",
                      }}
                    >
                      <Link
                        to="/"
                        sx={{
                          position: ["relative", "absolute"],
                          display: ["none", "block", "block"],
                          // left: ["0%", "12px"],
                        }}
                      >
                        <div
                          sx={{
                            width: "326px",
                          }}
                        >
                          <Logo />
                        </div>
                      </Link>
                      <div
                        sx={{
                          display: "flex",
                          gap: "1rem",
                          justifyContent: ["center", "flex-end"],
                          // width: "calc(100% - 360px)",
                          alignItems: ["center", "flex-end"],
                        }}
                      >
                        <Menu
                          home={this.props.home}
                          open={this.state.menuOpen}
                        />
                      </div>
                    </div>
                  </Container>
                )}
              </div>
            </Container>
          </header>
        )}
      />
    )
  }
}

export default Header
