/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Logo = ({ displayLogo = "" }) => {
  const data = useStaticQuery(query)

  if (displayLogo === "onlyLogo") {
    return (
      <GatsbyImage
        image={data.settings.logo.asset.gatsbyImageData}
        alt="logo"
      />
    )
  }

  return (
    <div
      sx={{
        backgroundColor: "transparent",
        width: "100%",
      }}
    >
      <div
        sx={{
          background: "rgba(249, 248, 246, 0.88);",
          display: "flex",
          justifyContent: "center",
          paddingTop: "16px",
        }}
      >
        <GatsbyImage
          image={data.settings.logo.asset.gatsbyImageData}
          alt="logo"
        />
      </div>
      <div
        sx={{
          background: "rgba(249, 248, 246, 0.88);",
          height: "3rem",
          clipPath: "polygon(50% 80%, 0 0, 100% 0)",
        }}
      />
    </div>
  )
}

export default Logo

const query = graphql`
  query LogoQuery {
    settings: sanitySiteSettings {
      logo {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`
