/** @jsx jsx */
import { jsx } from "theme-ui"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import SocialLinks from "./atoms/SocialLinks"
import Logo from "./Logo"
import scrollTo from "gatsby-plugin-smoothscroll"

const Menu = props => {
  function menuPath(sanityPath) {
    if (sanityPath === "home") {
      return "#search-results"
    } else {
      return sanityPath
    }
  }
  return (
    <StaticQuery
      query={graphql`
        query MenuQuery {
          menu: sanityMenu {
            title
            _rawChildren(resolveReferences: { maxDepth: 10 })
          }
        }
      `}
      render={data => (
        <div
          sx={{
            width: ["326px", "100%", "100%"],
            maxWidth: "100%",
          }}
        >
          <div
            sx={{
              width: ["326px", "100%", "100%"],
              maxWidth: "100%",
              backgroundColor: "rgba(249, 248, 246, .88)",
            }}
          >
            <div
              sx={{
                display: ["block", "none"],
                zIndex: props.open ? "1" : "1",
                position: "relative",
                // backgroundColor: "rgba(249, 248, 246, .88)",
                height: props.open ? "380px" : "170px",
                overflow: "hidden",
                transition: "height 1s",
              }}
            >
              <div
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  transition: "transform 1s",
                  transform: props.open
                    ? "translatey(0px)"
                    : "translatey(-208px)",
                }}
              >
                <MenuItems data={data} menuPath={menuPath} />
                <div
                  sx={{
                    margin: "2rem auto 0",
                  }}
                >
                  <SocialLinks dark={true} />
                </div>
              </div>
              <div
                className="mobile-logo"
                sx={{
                  backgroundColor: "transparent",
                  width: "100%",
                  transition: "transform 1s",
                  transform: props.open
                    ? "translatey(0px)"
                    : "translatey(-198px)",
                }}
              >
                <Link
                  to="/"
                  sx={{
                    width: "100%",
                    marginTop: "1rem",
                    display: ["flex", "none", "none"],
                    justifyContent: "center",
                  }}
                >
                  <Logo displayLogo="onlyLogo" />
                </Link>
              </div>
            </div>
          </div>

          <div
            sx={{
              background: "rgba(249, 248, 246, 0.88)",
              height: "3rem",
              clipPath: "polygon(50% 80%, 0 0, 100% 0)",
              display: ["block", "none"],
            }}
          />

          {/* High Resulution Menu */}
          <div
            sx={{
              display: ["none", "flex"],
              justifyContent: ["flex-end"],
            }}
          >
            <MenuItems home={props.home} data={data} menuPath={menuPath} />
            <div
              sx={{
                display: ["none", "block"],
                marginTop: "20px",
                marginLeft: "6.25%",
              }}
            >
              <SocialLinks />
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default Menu

const MenuItems = ({ home, data, menuPath }) => {
  return data.menu?._rawChildren.map((menuItem, index) => (
    <div
      key={index}
      sx={{
        textTransform: "uppercase",
        position: "relative",
        marginTop: ["25px", "22px"],
        marginRight: ["auto", "0px"],
        marginLeft: ["auto", "6.25%"],

        a: {
          fontSize: "1.1rem",
          color: ["#575F6B", "#ffffff"],
        },
      }}
    >
      {home && menuItem.children.document.slug.current == "home" ? (
        <a
          activeClassName="active"
          href="#search-results"
          sx={{
            textDecoration: "none",
            fontSize: ["1.6rem", "1.125rem", "1.125rem"],
            padding: "0px",
            boxSizing: "border-box",
            height: ["auto", "100%", "100%", "100%"],
            fontSize: "1.5rem",
            whiteSpace: "nowrap",
          }}
          onClick={() => scrollTo("#search-results")}
        >
          Properties
        </a>
      ) : (
        <AnchorLink
          activeClassName="active"
          sx={{
            textDecoration: "none",
            fontSize: ["1.6rem", "1.125rem", "1.125rem"],
            padding: "0px",
            boxSizing: "border-box",
            height: ["auto", "100%", "100%", "100%"],
            fontSize: "1.5rem",
            whiteSpace: "nowrap",
          }}
          to={"/" + menuPath(menuItem.children.document.slug.current)}
          title={menuItem.children.title}
        />
      )}
    </div>
  ))
}
